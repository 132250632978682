<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <title>shrink</title>
        <path d="M3 0h2v4c0 0.552-0.448 1-1 1h-4v-2h3v-3z"></path>
        <path d="M11 0h2v3h3v2h-4c-0.552 0-1-0.448-1-1v-4z"></path>
        <path d="M12 11h4v2h-3v3h-2v-4c0-0.552 0.448-1 1-1z"></path>
        <path d="M0 11h4c0.552 0 1 0.448 1 1v4h-2v-3h-3v-2z"></path>
    </svg>
</template>

<script>
export default {
    name: "svg-shrink"
};
</script>
