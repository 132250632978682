<template>
    <svg
        class="svg-inline--fa"
        width="40"
        height="28"
        viewBox="0 0 40 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.24119 7.13141C3.24119 9.88988 5.43002 12.0962 8.16639 12.0962C10.9028 12.0962 13.0916 9.8898 13.0916 7.13141C13.0916 4.37302 10.9028 2.16658 8.16639 2.16658C5.42994 2.12348 3.24119 4.37302 3.24119 7.13141Z"
            fill="white"
        />
        <path
            d="M17.7655 0.00143231C16.5864 -0.0416678 15.6177 0.893311 15.6177 2.08022V3.35336C15.7016 7.76608 14.733 10.9489 12.8813 12.5208C12.628 12.733 12.3764 12.9021 12.1659 13.1143C11.0296 13.8785 9.68275 14.3443 8.20928 14.3443C6.5253 14.3443 4.9252 13.7509 3.70507 12.7313C1.64124 13.5818 0 15.3224 0 18.25V26.9406C0 27.534 0.463766 27.9584 1.00971 27.9584H15.2805C15.8692 27.9584 16.2902 27.4909 16.2902 26.9406L16.2919 17.698C16.1653 16.2126 16.5024 14.7704 17.3016 13.5387C18.9428 11.0769 19.7437 7.64063 19.6598 3.26908V2.16501C19.7026 1.0626 18.8607 0.0445494 17.7655 0.00143231Z"
            fill="white"
        />
        <path
            d="M23.5327 7.13141C23.5327 9.88988 25.7215 12.0962 28.4579 12.0962C31.1942 12.0962 33.383 9.8898 33.383 7.13141C33.383 4.37302 31.1942 2.16658 28.4579 2.16658C25.723 2.12348 23.5327 4.37302 23.5327 7.13141Z"
            fill="white"
        />
        <path
            d="M38.0569 0.00143231C36.8778 -0.0416678 35.9092 0.893311 35.9092 2.08022V3.35336C35.9931 7.76607 35.0244 10.9489 33.1727 12.5208C32.9195 12.733 32.6679 12.9021 32.4574 13.1143C31.321 13.8785 29.9742 14.3443 28.5007 14.3443C26.8167 14.3443 25.2166 13.7509 23.9965 12.7313C21.9343 13.6646 20.3342 15.4053 20.3342 18.2916V26.9822C20.3342 27.5756 20.798 28 21.3439 28H35.6147C36.2035 28 36.6244 27.5325 36.6244 26.9822L36.6261 17.6981C36.4995 16.2128 36.8366 14.7706 37.6358 13.5388C39.277 11.0771 40.0779 7.6408 39.994 3.26925V2.16518C39.994 1.06277 39.1522 0.0445494 38.0569 0.00143231Z"
            fill="white"
        />
    </svg>
</template>

<script>
export default {
    name: "svg-hands"
};
</script>
