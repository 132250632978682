import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { ChimeUser } from "@/types/interfaces";
import { getApiClient } from "@/services/api";
import Store from "../index";

const endpoint = `appointments`;

// Todo create meetings.ts vuex module
const meetingsEndpoint = `meetings`;

@Module({
    dynamic: true,
    store: Store,
    name: "Appointments",
    namespaced: true
})
export default class Appointments extends VuexModule {
    deletedAppointments: Array<string> = [];
    removedBy = {};

    @Mutation
    public setRemovedBy(payload: ChimeUser) {
        this.removedBy = payload;
    }

    @Mutation
    public addDeletedAppointment(item: string) {
        this.deletedAppointments.push(item);
    }

    @Action({ commit: "addDeletedAppointment", rawError: true })
    deleteAppointment(appointmentId: string) {
        const token = this.context.rootGetters.idToken;
        return new Promise((resolve, reject) => {
            getApiClient()
                .delete(`/${endpoint}/${appointmentId}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then(() => {
                    return resolve(appointmentId);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    getMeetingRoom(appointmentId: string) {
        const token = this.context.rootGetters.idToken;
        return getApiClient().get(`/${endpoint}/${appointmentId}`, {
            headers: {
                Authorization: `bearer ${token}`
            }
        });
    }

    @Action({ rawError: true })
    leaveMeetingRoom(payload: Record<string, string>) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .delete(
                    `/${meetingsEndpoint}/${payload.meetingId}/attendee/${payload.attendeeId}`,
                    {
                        headers: {
                            Authorization: `bearer ${token}`
                        }
                    }
                )
                .then(() => {
                    return resolve("ok");
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
