<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="16"
        viewBox="0 0 20 16"
    >
        <title>connection2</title>
        <path
            d="M10 9c1.654 0 3.154 0.673 4.241 1.759l-1.414 1.414c-0.724-0.724-1.724-1.173-2.827-1.173s-2.103 0.449-2.827 1.173l-1.414-1.414c1.086-1.086 2.586-1.759 4.241-1.759v0z"
        ></path>
        <path
            d="M2.929 7.929c1.889-1.889 4.4-2.929 7.071-2.929s5.182 1.040 7.071 2.929l-1.414 1.414c-1.511-1.511-3.52-2.343-5.657-2.343s-4.146 0.832-5.657 2.343l-1.414-1.414z"
        ></path>
        <path
            d="M9 14c0-0.552 0.448-1 1-1s1 0.448 1 1-0.448 1-1 1-1-0.448-1-1z"
        ></path>
        <path
            d="M13.125 3.409c0.084 0.022 0.167 0.046 0.25 0.070v-2.070c-0.083-0.021-0.166-0.041-0.25-0.060v2.060z"
        ></path>
        <path
            d="M12.625 3.286c0.084 0.019 0.167 0.038 0.25 0.058v-2.050c-0.083-0.017-0.166-0.034-0.25-0.050v2.041z"
        ></path>
        <path
            d="M14.625 3.92c0.084 0.035 0.167 0.071 0.25 0.108v-2.155c-0.083-0.031-0.166-0.061-0.25-0.091v2.138z"
        ></path>
        <path
            d="M13.625 3.554c0.084 0.026 0.167 0.054 0.25 0.082v-2.094c-0.083-0.024-0.166-0.047-0.25-0.070v2.081z"
        ></path>
        <path
            d="M14.125 3.725c0.084 0.031 0.167 0.062 0.25 0.095v-2.122c-0.083-0.027-0.166-0.054-0.25-0.080v2.107z"
        ></path>
        <path
            d="M11.125 3.052c0.084 0.008 0.167 0.016 0.25 0.026v-2.011c-0.083-0.008-0.167-0.015-0.25-0.022v2.007z"
        ></path>
        <path
            d="M10.125 3.001c0.083 0.001 0.167 0.003 0.25 0.005v-2.001c-0.083-0.002-0.167-0.004-0.25-0.004v2z"
        ></path>
        <path
            d="M12.125 3.187c0.084 0.015 0.167 0.030 0.25 0.047v-2.034c-0.083-0.014-0.166-0.028-0.25-0.040v2.027z"
        ></path>
        <path
            d="M11.625 3.108c0.084 0.011 0.167 0.023 0.25 0.036v-2.021c-0.083-0.011-0.167-0.022-0.25-0.031v2.015z"
        ></path>
        <path
            d="M10.625 3.016c0.084 0.004 0.167 0.009 0.25 0.015v-2.005c-0.083-0.005-0.167-0.009-0.25-0.013v2.002z"
        ></path>
        <path
            d="M19.125 5.875l0.25-0.25v-1.023c-0.083-0.075-0.166-0.148-0.25-0.221v1.493z"
        ></path>
        <path
            d="M18.625 6.375l0.25-0.25v-1.954c-0.083-0.068-0.166-0.135-0.25-0.201v2.405z"
        ></path>
        <path
            d="M18.125 6.169c0.084 0.078 0.168 0.157 0.25 0.237v-2.627c-0.083-0.062-0.166-0.123-0.25-0.183v2.573z"
        ></path>
        <path
            d="M19.625 4.834v0.541l0.25-0.25v-0.049c-0.082-0.082-0.166-0.163-0.25-0.243z"
        ></path>
        <path
            d="M15.125 1.968v2.175c0.084 0.040 0.167 0.080 0.25 0.122v-2.195c-0.083-0.035-0.166-0.069-0.25-0.102z"
        ></path>
        <path
            d="M15.625 4.394c0.084 0.045 0.167 0.090 0.25 0.137v-2.242c-0.083-0.038-0.166-0.076-0.25-0.113v2.217z"
        ></path>
        <path
            d="M16.125 4.675c0.084 0.050 0.167 0.101 0.25 0.153v-2.296c-0.083-0.042-0.166-0.084-0.25-0.124v2.268z"
        ></path>
        <path
            d="M17.125 5.341c0.084 0.062 0.167 0.126 0.25 0.19v-2.435c-0.083-0.051-0.166-0.102-0.25-0.151v2.396z"
        ></path>
        <path
            d="M17.625 5.733c0.084 0.069 0.167 0.14 0.25 0.212v-2.523c-0.083-0.056-0.166-0.112-0.25-0.166v2.477z"
        ></path>
        <path
            d="M16.625 4.99c0.084 0.056 0.167 0.113 0.25 0.171v-2.36c-0.083-0.047-0.166-0.093-0.25-0.138v2.327z"
        ></path>
        <path
            d="M3.125 5.161c0.083-0.058 0.166-0.115 0.25-0.171v-2.327c-0.084 0.045-0.167 0.091-0.25 0.138v2.36z"
        ></path>
        <path
            d="M9.625 3.006c0.083-0.003 0.167-0.004 0.25-0.005v-2c-0.083 0.001-0.167 0.002-0.25 0.004v2.001z"
        ></path>
        <path
            d="M3.625 4.829c0.083-0.052 0.166-0.103 0.25-0.153v-2.268c-0.084 0.041-0.167 0.082-0.25 0.124v2.296z"
        ></path>
        <path
            d="M2.125 5.945c0.083-0.072 0.166-0.143 0.25-0.212v-2.477c-0.084 0.055-0.167 0.11-0.25 0.166v2.523z"
        ></path>
        <path
            d="M4.125 2.289v2.242c0.083-0.047 0.166-0.092 0.25-0.137v-2.217c-0.084 0.037-0.167 0.074-0.25 0.113z"
        ></path>
        <path
            d="M0.125 5.125l0.25 0.25v-0.542c-0.084 0.080-0.168 0.161-0.25 0.243v0.049z"
        ></path>
        <path
            d="M1.625 6.406c0.082-0.080 0.166-0.159 0.25-0.237v-2.573c-0.084 0.060-0.167 0.121-0.25 0.183v2.627z"
        ></path>
        <path
            d="M4.625 4.264c0.083-0.042 0.166-0.082 0.25-0.122v-2.175c-0.084 0.033-0.167 0.067-0.25 0.102v2.195z"
        ></path>
        <path
            d="M0.625 5.625l0.25 0.25v-1.493c-0.084 0.072-0.167 0.146-0.25 0.221v1.023z"
        ></path>
        <path
            d="M1.125 6.125l0.25 0.25v-2.405c-0.084 0.066-0.167 0.133-0.25 0.201v1.954z"
        ></path>
        <path
            d="M2.625 5.532c0.083-0.065 0.166-0.128 0.25-0.19v-2.396c-0.084 0.050-0.167 0.1-0.25 0.151v2.435z"
        ></path>
        <path
            d="M5.125 4.028c0.083-0.037 0.166-0.073 0.25-0.108v-2.138c-0.084 0.029-0.167 0.060-0.25 0.091v2.155z"
        ></path>
        <path
            d="M8.625 3.078c0.083-0.009 0.166-0.018 0.25-0.026v-2.007c-0.083 0.007-0.167 0.014-0.25 0.022v2.011z"
        ></path>
        <path
            d="M9.125 3.031c0.083-0.006 0.166-0.011 0.25-0.015v-2.002c-0.083 0.004-0.167 0.008-0.25 0.013v2.005z"
        ></path>
        <path
            d="M7.625 3.234c0.083-0.017 0.166-0.032 0.25-0.047v-2.027c-0.083 0.013-0.167 0.026-0.25 0.040v2.034z"
        ></path>
        <path
            d="M8.125 3.145c0.083-0.013 0.166-0.025 0.25-0.036v-2.015c-0.083 0.010-0.167 0.020-0.25 0.031v2.021z"
        ></path>
        <path
            d="M6.125 3.637c0.083-0.028 0.166-0.056 0.25-0.082v-2.081c-0.084 0.022-0.167 0.046-0.25 0.070v2.093z"
        ></path>
        <path
            d="M5.625 3.819c0.083-0.032 0.166-0.064 0.25-0.095v-2.107c-0.084 0.026-0.167 0.053-0.25 0.080v2.122z"
        ></path>
        <path
            d="M7.125 3.345c0.083-0.020 0.166-0.040 0.25-0.058v-2.041c-0.083 0.016-0.167 0.033-0.25 0.050v2.050z"
        ></path>
        <path
            d="M6.625 3.479c0.083-0.024 0.166-0.047 0.25-0.070v-2.060c-0.084 0.019-0.167 0.039-0.25 0.060v2.070z"
        ></path>
    </svg>
</template>

<script>
export default {
    name: "svg-connection-2"
};
</script>
