<template>
    <transition-group name="page-fade" tag="div" class="message-list">
        <article
            class="flex flex-col md:flex-row media pb-6 border-0 m-0 pt-0 message-list__message"
            v-for="message in messageData"
            :key="`${message.id}-${message.time}`"
            :class="{
                'message-list__my-message': myBespeakeId === message.id,
                'message-list__message-for-me': messageIsForMe(message.to)
            }"
        >
            <figure v-if="enableThumbnail" class="media-left">
                <p class="image is-64x64">
                    <img
                        class="is-rounded"
                        src="https://bulma.io/images/placeholders/128x128.png"
                        alt=""
                    />
                </p>
            </figure>

            <div class="media-content content w-full">
                <div class="is-marginless flex-vertical">
                    <div
                        class="message-list__name leading-none truncate"
                        :class="{
                            'opacity-75': dimMeta
                        }"
                    >
                        <span class="message-from">{{
                            textForName(message.name)
                        }}</span>
                        <span class="mx-1">to</span>
                        <span class="message-to">{{
                            textForName(message.to)
                        }}</span>
                        <span v-if="'everyone' !== message.to" class="ml-1"
                            >(private)</span
                        >
                    </div>

                    <!-- 
                        The inline styles below are same google meet uses. 
                        This is done to preserve spaces formatted in a textarea.
                    -->
                    <span
                        v-html="message.message"
                        class="break-links my-1"
                        style="white-space: pre-wrap; word-wrap: break-word;"
                    ></span>

                    <span
                        :class="{
                            'opacity-75': dimMeta
                        }"
                        class="text-xs message-list__meta"
                    >
                        {{ MgFormatISODateTime(message.time, "date-time") }}
                    </span>
                </div>
            </div>
        </article>
    </transition-group>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
    name: "message-list",
    props: {
        dimMeta: {
            type: Boolean
        },
        disableNameLink: {
            type: Boolean
        },
        enableThumbnail: {
            type: Boolean
        },
        messageData: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    computed: {
        ...mapState(["route"]),
        ...mapGetters(["userInfo", "myBespeakeId"])
    },

    methods: {
        messageIsForMe(messageFor = "") {
            return Boolean(this.userInfo.name === messageFor);
        },
        textForName(name = "") {
            if (this.userInfo.name === name) {
                return "Me";
            } else {
                return name;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import "../styles/components/message-list";
</style>
