<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <title>enlarge</title>
        <path d="M2 5h-2v-4c0-0.552 0.448-1 1-1h4v2h-3v3z"></path>
        <path d="M16 5h-2v-3h-3v-2h4c0.552 0 1 0.448 1 1v4z"></path>
        <path d="M15 16h-4v-2h3v-3h2v4c0 0.552-0.448 1-1 1z"></path>
        <path d="M5 16h-4c-0.552 0-1-0.448-1-1v-4h2v3h3v2z"></path>
    </svg>
</template>

<script>
export default {
    name: "svg-enlarge"
};
</script>
