<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <title>video-camera</title>
        <path
            d="M15.294 4.004c-0.165 0-0.335 0.058-0.491 0.168l-2.803 1.978v-1.651c0-0.825-0.675-1.5-1.5-1.5h-9c-0.825 0-1.5 0.675-1.5 1.5v7c0 0.825 0.675 1.5 1.5 1.5h9c0.825 0 1.5-0.675 1.5-1.5v-1.65l2.803 1.978c0.156 0.11 0.326 0.168 0.491 0.168 0 0 0 0 0 0 0.22 0 0.421-0.101 0.551-0.278 0.101-0.137 0.154-0.313 0.154-0.51v-6.415c-0-0.518-0.355-0.788-0.706-0.788z"
        ></path>
    </svg>
</template>

<script>
export default {
    name: "svg-camera"
};
</script>
