var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{staticClass:"message-list",attrs:{"name":"page-fade","tag":"div"}},_vm._l((_vm.messageData),function(message){return _c('article',{key:((message.id) + "-" + (message.time)),staticClass:"flex flex-col md:flex-row media pb-6 border-0 m-0 pt-0 message-list__message",class:{
            'message-list__my-message': _vm.myBespeakeId === message.id,
            'message-list__message-for-me': _vm.messageIsForMe(message.to)
        }},[(_vm.enableThumbnail)?_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"},[_c('img',{staticClass:"is-rounded",attrs:{"src":"https://bulma.io/images/placeholders/128x128.png","alt":""}})])]):_vm._e(),_c('div',{staticClass:"media-content content w-full"},[_c('div',{staticClass:"is-marginless flex-vertical"},[_c('div',{staticClass:"message-list__name leading-none truncate",class:{
                        'opacity-75': _vm.dimMeta
                    }},[_c('span',{staticClass:"message-from"},[_vm._v(_vm._s(_vm.textForName(message.name)))]),_c('span',{staticClass:"mx-1"},[_vm._v("to")]),_c('span',{staticClass:"message-to"},[_vm._v(_vm._s(_vm.textForName(message.to)))]),('everyone' !== message.to)?_c('span',{staticClass:"ml-1"},[_vm._v("(private)")]):_vm._e()]),_c('span',{staticClass:"break-links my-1",staticStyle:{"white-space":"pre-wrap","word-wrap":"break-word"},domProps:{"innerHTML":_vm._s(message.message)}}),_c('span',{staticClass:"text-xs message-list__meta",class:{
                        'opacity-75': _vm.dimMeta
                    }},[_vm._v(" "+_vm._s(_vm.MgFormatISODateTime(message.time, "date-time"))+" ")])])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }