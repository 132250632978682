<template>
    <div>
        <label
            :for="`${_uid}-select`"
            :class="{
                'sr-only': !label
            }"
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        >
            <span v-if="label">{{ label }}</span>
            <span v-else>Please make a selection</span>
        </label>
        <div class="relative w-full">
            <select
                :id="`${_uid}-select`"
                @change="selectChange"
                v-model="selected"
                class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline m-0"
            >
                <option value="">select</option>
                <option
                    v-for="(el, index) in selectOptions"
                    :key="index"
                    :value="el.deviceId"
                    >{{ el.label }}</option
                >
            </select>
            <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 text-primary"
            >
                <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import eventHub from "@/event-hub";

// TODO: deprecate this component - use select.vue instead.
export default {
    name: "device-select",

    props: {
        device: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        "select-options": {
            type: Array,
            default() {
                return [];
            }
        }
    },

    data() {
        return {
            selected: ""
        };
    },

    created() {
        let savedValue = "";

        switch (this.device) {
            case "audio_input":
                savedValue = localStorage.getItem("preferredAudioInput");
                break;
            case "audio_output":
                savedValue = localStorage.getItem("preferredAudioOutput");
                break;
            case "video_input":
                savedValue = localStorage.getItem("preferredVideoInput");
                break;
            default:
                // n/a
                break;
        }

        if (savedValue && "undefined" !== savedValue) {
            this.selected = savedValue;
        }
    },

    methods: {
        selectChange($event) {
            const payload = {
                device: this.device,
                value: $event.target.value
            };

            eventHub.$emit("speakeasy-device-selection", payload);
        }
    }
};
</script>
