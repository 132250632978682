<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <title>mic-off</title>
        <path
            d="M10 2.5c0-1.381-1.119-2.5-2.5-2.5s-2.5 1.119-2.5 2.5v5.379l5-5v-0.379zM4.018 8.86c-0.012-0.118-0.018-0.239-0.018-0.36v-1.5h-1v1.5c0 0.42 0.058 0.827 0.166 1.213l0.853-0.853zM11 8.5c0 1.933-1.567 3.5-3.5 3.5-1.041 0-1.975-0.455-2.617-1.176l0.709-0.709c0.458 0.541 1.142 0.885 1.907 0.885 1.381 0 2.5-1.119 2.5-2.5v-2.793l4.010-4.010-0.707-0.707-3.303 3.303-9.969 10.010 0.707 0.707 3.437-3.478c0.717 0.786 1.709 1.317 2.825 1.441v2.028h-2v1h5v-1h-2v-2.028c2.25-0.249 4-2.156 4-4.472v-1.5h-1v1.5z"
        ></path>
    </svg>
</template>

<script>
export default {
    name: "svg-mic-off"
};
</script>
