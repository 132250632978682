<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="16"
        viewBox="0 0 20 16"
    >
        <title>connection3</title>
        <path
            d="M10 9c1.654 0 3.154 0.673 4.241 1.759l-1.414 1.414c-0.724-0.724-1.724-1.173-2.827-1.173s-2.103 0.449-2.827 1.173l-1.414-1.414c1.086-1.086 2.586-1.759 4.241-1.759v0z"
        ></path>
        <path
            d="M9 14c0-0.552 0.448-1 1-1s1 0.448 1 1-0.448 1-1 1-1-0.448-1-1z"
        ></path>
        <path
            d="M13.125 3.409c0.084 0.022 0.167 0.046 0.25 0.070v-2.070c-0.083-0.021-0.166-0.041-0.25-0.060v2.060z"
        ></path>
        <path
            d="M12.625 3.286c0.084 0.019 0.167 0.038 0.25 0.058v-2.050c-0.083-0.017-0.166-0.034-0.25-0.050v2.041z"
        ></path>
        <path
            d="M14.625 3.92c0.084 0.035 0.167 0.071 0.25 0.108v-2.155c-0.083-0.031-0.166-0.061-0.25-0.091v2.138z"
        ></path>
        <path
            d="M13.625 3.554c0.084 0.026 0.167 0.054 0.25 0.082v-2.094c-0.083-0.024-0.166-0.047-0.25-0.070v2.081z"
        ></path>
        <path
            d="M14.125 3.725c0.084 0.031 0.167 0.062 0.25 0.095v-2.122c-0.083-0.027-0.166-0.054-0.25-0.080v2.107z"
        ></path>
        <path
            d="M11.125 3.052c0.084 0.008 0.167 0.016 0.25 0.026v-2.011c-0.083-0.008-0.167-0.015-0.25-0.022v2.007z"
        ></path>
        <path
            d="M10.125 3.001c0.083 0.001 0.167 0.003 0.25 0.005v-2.001c-0.083-0.002-0.167-0.004-0.25-0.004v2z"
        ></path>
        <path
            d="M12.125 3.187c0.084 0.015 0.167 0.030 0.25 0.047v-2.034c-0.083-0.014-0.166-0.028-0.25-0.040v2.027z"
        ></path>
        <path
            d="M11.625 3.108c0.084 0.011 0.167 0.023 0.25 0.036v-2.021c-0.083-0.011-0.167-0.022-0.25-0.031v2.015z"
        ></path>
        <path
            d="M10.625 3.016c0.084 0.004 0.167 0.009 0.25 0.015v-2.005c-0.083-0.005-0.167-0.009-0.25-0.013v2.002z"
        ></path>
        <path
            d="M19.125 5.875l0.25-0.25v-1.023c-0.083-0.075-0.166-0.148-0.25-0.221v1.493z"
        ></path>
        <path
            d="M18.625 6.375l0.25-0.25v-1.954c-0.083-0.068-0.166-0.135-0.25-0.201v2.405z"
        ></path>
        <path
            d="M18.125 6.169c0.084 0.078 0.168 0.157 0.25 0.237v-2.627c-0.083-0.062-0.166-0.123-0.25-0.183v2.573z"
        ></path>
        <path
            d="M19.625 4.834v0.541l0.25-0.25v-0.049c-0.082-0.082-0.166-0.163-0.25-0.243z"
        ></path>
        <path
            d="M15.125 1.968v2.175c0.084 0.040 0.167 0.080 0.25 0.122v-2.195c-0.083-0.035-0.166-0.069-0.25-0.102z"
        ></path>
        <path
            d="M15.625 4.394c0.084 0.045 0.167 0.090 0.25 0.137v-2.242c-0.083-0.038-0.166-0.076-0.25-0.113v2.217z"
        ></path>
        <path
            d="M16.125 4.675c0.084 0.050 0.167 0.101 0.25 0.153v-2.296c-0.083-0.042-0.166-0.084-0.25-0.124v2.268z"
        ></path>
        <path
            d="M17.125 5.341c0.084 0.062 0.167 0.126 0.25 0.19v-2.435c-0.083-0.051-0.166-0.102-0.25-0.151v2.396z"
        ></path>
        <path
            d="M17.625 5.733c0.084 0.069 0.167 0.14 0.25 0.212v-2.523c-0.083-0.056-0.166-0.112-0.25-0.166v2.477z"
        ></path>
        <path
            d="M16.625 4.99c0.084 0.056 0.167 0.113 0.25 0.171v-2.36c-0.083-0.047-0.166-0.093-0.25-0.138v2.327z"
        ></path>
        <path
            d="M3.125 5.161c0.083-0.058 0.166-0.115 0.25-0.171v-2.327c-0.084 0.045-0.167 0.091-0.25 0.138v2.36z"
        ></path>
        <path
            d="M9.625 3.006c0.083-0.003 0.167-0.004 0.25-0.005v-2c-0.083 0.001-0.167 0.002-0.25 0.004v2.001z"
        ></path>
        <path
            d="M3.625 4.829c0.083-0.052 0.166-0.103 0.25-0.153v-2.268c-0.084 0.041-0.167 0.082-0.25 0.124v2.296z"
        ></path>
        <path
            d="M2.125 5.945c0.083-0.072 0.166-0.143 0.25-0.212v-2.477c-0.084 0.055-0.167 0.11-0.25 0.166v2.523z"
        ></path>
        <path
            d="M4.125 2.289v2.242c0.083-0.047 0.166-0.092 0.25-0.137v-2.217c-0.084 0.037-0.167 0.074-0.25 0.113z"
        ></path>
        <path
            d="M0.125 5.125l0.25 0.25v-0.542c-0.084 0.080-0.168 0.161-0.25 0.243v0.049z"
        ></path>
        <path
            d="M1.625 6.406c0.082-0.080 0.166-0.159 0.25-0.237v-2.573c-0.084 0.060-0.167 0.121-0.25 0.183v2.627z"
        ></path>
        <path
            d="M4.625 4.264c0.083-0.042 0.166-0.082 0.25-0.122v-2.175c-0.084 0.033-0.167 0.067-0.25 0.102v2.195z"
        ></path>
        <path
            d="M0.625 5.625l0.25 0.25v-1.493c-0.084 0.072-0.167 0.146-0.25 0.221v1.023z"
        ></path>
        <path
            d="M1.125 6.125l0.25 0.25v-2.405c-0.084 0.066-0.167 0.133-0.25 0.201v1.954z"
        ></path>
        <path
            d="M2.625 5.532c0.083-0.065 0.166-0.128 0.25-0.19v-2.396c-0.084 0.050-0.167 0.1-0.25 0.151v2.435z"
        ></path>
        <path
            d="M5.125 4.028c0.083-0.037 0.166-0.073 0.25-0.108v-2.138c-0.084 0.029-0.167 0.060-0.25 0.091v2.155z"
        ></path>
        <path
            d="M8.625 3.078c0.083-0.009 0.166-0.018 0.25-0.026v-2.007c-0.083 0.007-0.167 0.014-0.25 0.022v2.011z"
        ></path>
        <path
            d="M9.125 3.031c0.083-0.006 0.166-0.011 0.25-0.015v-2.002c-0.083 0.004-0.167 0.008-0.25 0.013v2.005z"
        ></path>
        <path
            d="M7.625 3.234c0.083-0.017 0.166-0.032 0.25-0.047v-2.027c-0.083 0.013-0.167 0.026-0.25 0.040v2.034z"
        ></path>
        <path
            d="M8.125 3.145c0.083-0.013 0.166-0.025 0.25-0.036v-2.015c-0.083 0.010-0.167 0.020-0.25 0.031v2.021z"
        ></path>
        <path
            d="M6.125 3.637c0.083-0.028 0.166-0.056 0.25-0.082v-2.081c-0.084 0.022-0.167 0.046-0.25 0.070v2.093z"
        ></path>
        <path
            d="M5.625 3.819c0.083-0.032 0.166-0.064 0.25-0.095v-2.107c-0.084 0.026-0.167 0.053-0.25 0.080v2.122z"
        ></path>
        <path
            d="M7.125 3.345c0.083-0.020 0.166-0.040 0.25-0.058v-2.041c-0.083 0.016-0.167 0.033-0.25 0.050v2.050z"
        ></path>
        <path
            d="M6.625 3.479c0.083-0.024 0.166-0.047 0.25-0.070v-2.060c-0.084 0.019-0.167 0.039-0.25 0.060v2.070z"
        ></path>
        <path
            d="M12.125 7.284c0.084 0.023 0.167 0.047 0.25 0.073v-2.074c-0.083-0.020-0.166-0.039-0.25-0.057v2.058z"
        ></path>
        <path
            d="M12.625 7.439c0.084 0.029 0.167 0.059 0.25 0.091v-2.112c-0.083-0.025-0.166-0.048-0.25-0.071v2.092z"
        ></path>
        <path
            d="M11.125 7.079c0.084 0.012 0.167 0.024 0.25 0.039v-2.023c-0.083-0.011-0.166-0.022-0.25-0.031v2.016z"
        ></path>
        <path
            d="M10.625 7.024c0.084 0.006 0.167 0.014 0.25 0.023v-2.009c-0.083-0.007-0.166-0.013-0.25-0.018v2.004z"
        ></path>
        <path
            d="M11.625 7.165c0.084 0.017 0.167 0.036 0.25 0.056v-2.045c-0.083-0.016-0.166-0.030-0.25-0.044v2.033z"
        ></path>
        <path
            d="M13.125 7.631c0.084 0.036 0.167 0.073 0.25 0.111v-2.161c-0.083-0.030-0.166-0.058-0.25-0.085v2.136z"
        ></path>
        <path
            d="M13.625 7.864c0.084 0.043 0.167 0.087 0.25 0.133v-2.222c-0.083-0.035-0.166-0.069-0.25-0.101v2.19z"
        ></path>
        <path
            d="M16.125 8.875l0.25-0.25v-1.33c-0.082-0.068-0.166-0.136-0.25-0.201v1.781z"
        ></path>
        <path
            d="M16.625 7.51v0.865l0.25-0.25v-0.387c-0.082-0.078-0.166-0.154-0.25-0.229z"
        ></path>
        <path
            d="M15.625 9.312c0.011 0.010 0.021 0.021 0.032 0.031l0.218-0.218v-2.219c-0.083-0.060-0.166-0.119-0.25-0.176v2.583z"
        ></path>
        <path
            d="M14.125 8.142c0.084 0.051 0.167 0.104 0.25 0.158v-2.297c-0.083-0.040-0.166-0.080-0.25-0.118v2.257z"
        ></path>
        <path
            d="M15.125 8.857c0.085 0.071 0.168 0.143 0.25 0.218v-2.511c-0.083-0.053-0.166-0.105-0.25-0.155v2.448z"
        ></path>
        <path
            d="M10.125 7.002c0.084 0.001 0.167 0.003 0.25 0.007v-2.001c-0.083-0.003-0.166-0.005-0.25-0.006v2z"
        ></path>
        <path
            d="M14.625 8.47c0.084 0.060 0.168 0.122 0.25 0.185v-2.392c-0.083-0.046-0.166-0.091-0.25-0.135v2.341z"
        ></path>
        <path
            d="M7.125 7.53c0.083-0.032 0.166-0.062 0.25-0.091v-2.092c-0.084 0.023-0.167 0.046-0.25 0.071v2.112z"
        ></path>
        <path
            d="M4.625 9.075c0.082-0.075 0.165-0.147 0.25-0.218v-2.448c-0.084 0.050-0.167 0.102-0.25 0.155v2.511z"
        ></path>
        <path
            d="M4.125 9.125l0.218 0.218c0.011-0.011 0.021-0.021 0.032-0.031v-2.583c-0.084 0.057-0.167 0.116-0.25 0.176v2.219z"
        ></path>
        <path
            d="M5.125 8.656c0.082-0.063 0.166-0.125 0.25-0.185v-2.341c-0.084 0.044-0.167 0.089-0.25 0.135v2.392z"
        ></path>
        <path
            d="M5.625 8.3c0.082-0.054 0.166-0.107 0.25-0.158v-2.257c-0.084 0.038-0.167 0.077-0.25 0.118v2.297z"
        ></path>
        <path
            d="M3.625 8.625l0.25 0.25v-1.781c-0.084 0.066-0.168 0.133-0.25 0.201v1.33z"
        ></path>
        <path
            d="M3.125 8.125l0.25 0.25v-0.865c-0.084 0.075-0.168 0.151-0.25 0.229v0.387z"
        ></path>
        <path
            d="M9.125 7.048c0.083-0.009 0.166-0.017 0.25-0.023v-2.004c-0.084 0.005-0.167 0.011-0.25 0.018v2.009z"
        ></path>
        <path
            d="M6.125 7.998c0.083-0.046 0.166-0.090 0.25-0.133v-2.19c-0.084 0.033-0.167 0.066-0.25 0.101v2.222z"
        ></path>
        <path
            d="M9.625 7.009c0.083-0.004 0.166-0.006 0.25-0.007v-2c-0.084 0.001-0.167 0.003-0.25 0.006v2.001z"
        ></path>
        <path
            d="M8.125 7.22c0.083-0.020 0.166-0.038 0.25-0.056v-2.033c-0.084 0.014-0.167 0.028-0.25 0.044v2.045z"
        ></path>
        <path
            d="M8.625 7.117c0.083-0.014 0.166-0.027 0.25-0.039v-2.016c-0.084 0.009-0.167 0.020-0.25 0.031v2.023z"
        ></path>
        <path
            d="M6.625 7.743c0.083-0.038 0.166-0.075 0.25-0.111v-2.136c-0.084 0.027-0.167 0.056-0.25 0.085v2.161z"
        ></path>
        <path
            d="M7.625 7.357c0.083-0.026 0.166-0.050 0.25-0.073v-2.058c-0.084 0.018-0.167 0.037-0.25 0.057v2.074z"
        ></path>
    </svg>
</template>

<script>
export default {
    name: "svg-connection-3"
};
</script>
